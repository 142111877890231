import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { HeroSection } from '../components/common/HeroWithBg';
import Layout from "../components/layout";
import SEO from "../components/seo";

import Img from 'gatsby-image';

import { motion } from "framer-motion";


const OverlaySubscribe = styled(Container)`
background-color: rgba(250, 188, 61, 0.9);
  padding: 0px;
  margin: 0px;
`;

const Forma = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 320px;
  }
`;

const PhotosGrid = styled(motion.div)`
  // max-height:320px;
  max-width:320px;
  margin: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
export const query = graphql`
  query {
    objektiImages: allFile(filter: {relativeDirectory: {eq: "objekti"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const Objekti = ({ data }) => {

  const imagesList = data.objektiImages.edges;

  return (
    <Layout pageInfo={{ pageName: "objekti" }}>
      <SEO title="Objekti" keywords={[`art`, `arhitektura`, `objekti`]} />

      <HeroSection>
        Objekti
      </HeroSection>

      <Container mb-3="true">
        <p>Pogledajte slike objekata, projekata, slike izvedenih objekata</p>
      </Container>
      <Container style={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap"
      }}>
        {/* <Row style={{
          
        }}> */}


        {imagesList.map((image, i) => (
          <PhotosGrid
            key={i}
            whileHover={{
              scale: 2,
              zIndex: 2,
            }}
          >
            <Img fluid={image.node.childImageSharp.fluid} alt="objekti slika" style={{ width: "100%", minWidth: "320px" }} />
          </PhotosGrid>
        ))}
      </Container>

      <Container fluid className="subscribe-section no-p-m">
        <OverlaySubscribe fluid className="no-p-m text-center pt-5 pb-5 d-flex justify-content-center">
          {/* <Container fluid className="text-center "> */}
          <Row>
            <Col xs={12} md={4}>
              <h2 className="display-5 mb-2">Prijavite se na našoj listi</h2>
            </Col>
            <Col xs={12} md={8} className="d-flex">
              <form className="form-inline w-100" name="subskrajberi" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                <p className="hidden sr-only">
                  <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                </p>
                <Forma className="form-group">
                  <label htmlFor="emailSubscription" className="sr-only">Email</label>
                  <input type="email" className="form-control form-control-lg w-100" id="emailSubscription" placeholder="Vaša email adresa" />
                </Forma>
                <button type="submit" className="btn btn-outline-secondary btn-lg">Prijavite se</button>
              </form>
            </Col>
          </Row>
          {/* </Container> */}
        </OverlaySubscribe>
      </Container>

    </Layout >
  );
}

export default Objekti;

